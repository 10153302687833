skel.on 'change', ->
  if skel.vars.stateId
    size = 'large'
    if skel.vars.stateId.indexOf('/small') >= 0
      size = 'small'
    else if skel.vars.stateId.indexOf('/large') >= 0
      size = 'medium'
$  ->
  window.hero_timeout = setTimeout(heroRotate, 5000)
  $('#hero').on 'click', '.hero-dot', (e) ->
    clearTimeout(window.hero_timeout)
    hero = $('#hero')
    hero.find('.hero-dot').removeClass('active')
    $(this).addClass('active')
    hero.find('.hero-img').css({ 'z-index': 0 })
    hero.find('.hero-img').eq($(this).index()).css({ 'z-index': 10 })
    window.hero_timeout = setTimeout(heroRotate, 5000)
    return
  $('#hero').on 'mouseover', (e) ->
    clearTimeout(window.hero_timeout)
    return
  $('#hero').on 'mouseout', (e) ->
    window.hero_timeout = setTimeout(heroRotate, 5000)
    return

heroRotate = ->
  clearTimeout(window.hero_timeout)
  if $('#hero').length > 0
    hero = $('#hero')
    heroCount = hero.find('.hero-dot').length
    heroCurrent = hero.find('.hero-dot.active').index()
    heroNext = heroCurrent + 1
    if heroNext >= heroCount
      heroNext = 0
    hero.find('.hero-dot').removeClass('active')
    hero.find('.hero-dot').eq(heroNext).addClass('active')
    hero.find('.hero-img').css({ 'z-index': 0 })
    hero.find('.hero-img').eq(heroNext).css({ 'z-index': 10 })
    
    window.hero_timeout = setTimeout(heroRotate, 5000)
  return